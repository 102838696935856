import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionType } from '../../entities/permissions';

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    appSlideMenuState: string = 'out';
    @Output() widthChanged = new EventEmitter();
    logo: string;
    PermissionType = PermissionType;
    isCollapsed = false;

    constructor(private router: Router) { }

    ngOnInit(): void {
        // This monitors for change on the sidebar and checks for the route so as to activate/show it.
        this.widthChanged.emit(this.appSlideMenuState);
        const theme = localStorage.getItem('Preferred-theme');
        theme === 'dark' ? this.logo = 'assets/images/logo/brill-analytics-dark-logo.svg' : this.logo = 'assets/images/logo/brill-analytics-white-logo.svg';
        if (this.isRouteActive('/devicemanagement') || this.isRouteActive('/deviceconfiguration') || this.isRouteActive('/admin')) {
            this.isCollapsed = true;
        }
    }

    isRouteActive(route: string): boolean {
        return this.router.url.startsWith(route);
    }

    explandSidebarPanel() {
        this.isCollapsed = !this.isCollapsed
    }
}
