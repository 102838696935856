import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrl: './modal-dialog.component.scss'
})
export class ModalDialogComponent {
    @Input() isModalVisible: boolean = false;
    @Output() onClose = new EventEmitter<any>();
    @Input() modalTitle: string = 'Update Failed';
    @Input() modalContent: TemplateRef<any>;
    @Input() iconClass: string = '';
    @Input() modalFooter: TemplateRef<any>;
    @Input() translateParams: any;


    closeDialog() {
        this.onClose.emit();
    }

}
