<p-dialog header="Header"
          [modal]="true"
          [(visible)]="isModalVisible"
          (onHide)="closeDialog()">
    <ng-template #defaultModalContent>
        <div></div>
    </ng-template>
    <ng-template #defaultModalFooter>
        <div></div>
    </ng-template>
    <ng-template pTemplate="header">
        <span class="headertext">
            <i class="pi {{iconClass}} error"></i>
            {{modalTitle | translate:translateParams}}</span>
    </ng-template>

    <ng-template pTemplate="content">
        <ng-container *ngTemplateOutlet="modalContent ? modalContent : defaultModalContent"></ng-container>
    </ng-template>

    <ng-template pTemplate="footer">
        <ng-container *ngTemplateOutlet="modalFooter ? modalFooter : defaultModalFooter"></ng-container>
    </ng-template>
</p-dialog>