<modal-dialog [modalTitle]="title"
              [isModalVisible]="isModalVisible"
              [modalContent]="modalContent"
              [iconClass]="'pi-exclamation-triangle'"
              [modalFooter]="modalFooter"
              (onClose)="closeDialog()">

    <ng-template #modalContent>
        {{errorMessage}}

        @if(modalType === 'show-gateway-logs') {
        <p-accordion (onOpen)="loadDeviceLogs($event)">
            <p-accordionTab header="Gateway Logs">
                @if(isLoading) {
                <p>Loading logs...</p>
                }
                @if(!isLoading && responseData) {
                <p>{{ responseData }}</p>
                }
                @if(!isLoading && logErrrorMessage) {
                <p>{{logErrrorMessage}}</p>
                }
            </p-accordionTab>
        </p-accordion>
        }
    </ng-template>

    <ng-template #modalFooter>
        <p-button icon="pi pi-arrow-left"
                  styleClass="cancel-btn"
                  (onClick)="closeDialog()"
                  label="{{'common.error.modal.close' | translate}}"></p-button>
        <p-button icon="pi pi-replay"
                  (onClick)="tryAgain()"
                  label="{{'common.error.modal.tryagain' | translate}}"></p-button>
    </ng-template>

</modal-dialog>